html {
  scroll-behavior: smooth;
}

.mainPage {
  margin: 0 auto;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  margin-top: 50px;
  max-width: 1024px;
  .page1 {
    .header {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 100%;
      max-width: 1024px;
      text-align: center;
    }
    .bg {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }
}

.goForm {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  opacity:1;
  transition: opacity 0.4s linear;

  &.hide {
    opacity: 0;
    transition: opacity 0.4s linear;
  }
  .clickBtn {
    border: 1px solid rgb(116, 116, 116);
    border-radius: 6px;
    width: 50%;
    display: flex;
    justify-content: center;
    background: #ffffff;
    padding: 5px;
    & > div {
      cursor: pointer;
      background: #ffcf00;
      border-radius: 32px;
      width: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      /* or 150% */

      /* Text/10 */

      color: #242c3f;
    }
  }
}
