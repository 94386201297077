.header {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;

  z-index: 1000;
  background-color: #f7f7f7;
  transition: background-color 0.4s linear;
  padding: 1%;
  .logo {
    cursor: pointer;
    height: 100%;
    & > img {
      height: 100%;
    }
  }
}
