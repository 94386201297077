.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  .bg {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    & > img {
      width: 100%;
    }
  }
  .text {
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 36px;
    color: #242c3f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
  }
  .text2 {
    text-align: center;
    background-color: #0a79db;
    padding: 2%;
    .message {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: 350;
      font-size: 18px;
      color: white;
    }
  }
  .bg2 {
    display: flex;
    padding: 20px;
    width: 100%;
    .imgContent {
      width: 25%;
      padding: 15px;
      & > img {
        width: 100%;
      }
      .content {
        .title {
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 24px;
          color: #3a4052;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 10px;
        }
        .txt {
          font-family: Noto Sans CJK TC;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #3a4052;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .page {
    .text {
      font-family: Noto Sans CJK TC;
      font-style: normal;
      font-weight: 800;
      font-size: 25px;
      line-height: 36px;
      color: #242c3f;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
    }
  }
}
