.page1 {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  .text {
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 36px;
    color: #242c3f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .bg {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
    .content {
      display: flex;
      justify-content: center;
      width: 75%;
      .imgContent {
        width: 50%;
        height: 100%;
        padding: 15px;
        & > div > img {
          width: 100%;
        }
      }
    }
  }
  .textContent {
    height: 100%;
    .area {
      .title {
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 40px;
        color: #3a4052;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
      }
      .textListContent {
        display: flex;
        justify-content: center;
        .textList {
          width: 80%;
          .buy {
            cursor: pointer;

            margin-top: 20px;
            background: #394f8a;
            border-radius: 8px;
            font-style: normal;
            font-weight: 400;
            //font-size: 18px;
            //font-size: calc(10px + 1.5vw);
            line-height: 36px;
            color: #242c3f;
            width: 100px;
            height: 11%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #42b0e3;
            background-image: linear-gradient(to bottom, #42b0e3, #005074);
            border: 1px solid #107db0;
            color: white;
            box-shadow: "0px 10px 5px -2px rgb(0 0 0 / 30%)";
            border-radius: 8px;
          }

          .buy:hover {
            background-image: linear-gradient(to bottom, #005074, #42b0e3);
          }
        }
      }
    }
  }
}
