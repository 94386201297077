.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  .bg {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    & > img {
      width: 100%;
    }
  }
  .bg2 {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 5%;
    & > img {
      width: 100%;
    }
  }
}

