.page1 {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  .bg {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    & > img {
      width: 100%;
    }
  }
  .text {
    font-family: Noto Sans CJK TC;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 36px;
    color: #242c3f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .bg2 {
    display: flex;
    padding: 20px;
    width: 100%;
    .imgContent {
      width: 33%;
      padding: 15px;
      & > img {
        width: 100%;
      }
    }
  }
}
