.page1 {
  position: relative;
  width: 100%;
  padding: 2%;
  display: flex;
  .bg {
    position: relative;
    text-align: center;
    width: 50%;
    height: 100%;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    color: #242c3f;
    width: 50%;
    background-color: #42b0e3;
    background-image: linear-gradient(to bottom, #42b0e3, #005074);
    border: 1px solid #107db0;
    color: white;
    .formContent {
      width: 100%;
      height: 565px;
      & > iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.endImg{
    cursor: pointer;
    width: 100%;
}

@media screen and (max-width: 500px) {
  .page1 {
    display: block;
    .bg {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}
